import { Device } from "./Device"
import { FirmwareProductByID, StatusColors } from "./consts"
import { Version } from "../../modules/Version"
import type {
	FirmwarePreferences,
	BaseEidosType,
	BaseDeviceRawData,
	DeviceStatusAppearance,
} from "."
import { LuxedoRPC } from "luxedo-rpc"
import { ThirdPartyProjectorManager } from "../../data/SupportedProjectors"
import { Lightshow, Scene } from "../.."

// #region 		================================= 	TYPES 	=================================

export type StatusRPiDevice =
	| "OFF"
	| "NEW"
	| "UPDATING"
	| "IDLE"
	| "PROJECT"
	| "PROJ_TT"
	| "PNC"
	| "LOST"
	| "DORMANT"
export type ProjectorPowerState = "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"

// These are pulled directly from each @endpoint method within the firmware
export const API_THREAD_DEVICE_ROUTES = [
	"hdmi_boost_apply",
	"hdmi_boost_remove",
	"config_update",
	"display_set_resolution",
	"load_timetable",
	"project_and_capture_v2",
	"launch_auto_exposure",
	"cancel_pnc",
	"download_show",
	"play_scene",
	"master_play_scene",
	"set_image_b64",
	"play_default",
	"activate_blending_preview",
	"group_configuration_updated",
	"stop_blending_preview",
	"projector_power_on",
	"projector_power_off",
	"run_rs232_command",
	"reboot",
	"restart",
	"ssh_tunnel_close",
	"ssh_tunnel_open",
	"update_trigger",
	"network_test"
] as const

export type ApiThreadDeviceRoute = (typeof API_THREAD_DEVICE_ROUTES)[number]

export type APIThreadStatus = {
	[key in ApiThreadDeviceRoute]?: {
		id: number
		args: any[]
		status: "NOT_STARTED" | "RUNNING" | "SUCCESS" | "ERROR"
		result: any
		error: string | null
		ts_end: number | null
	}
}

export type Lux2LuxEidosStatus =
	| {
			role: "unknown"
			connected: false
	  }
	| {
			role: "master"
			connected: boolean
			master_ip: string
			client_connections: { [id: number]: boolean }
	  }
	| {
			role: "client"
			connected: boolean
			master_ip: string
	  }

export interface LuxcastFWPreferences extends FirmwarePreferences {
	show_wifi_info?: boolean
	audio_device?: "HDMI" | "HEADPHONES"
	projector_keep_alive_duration?: number
	hdmi_boost?: boolean

	// Removed with firmware version 3.1.8
	// power_control_type?: "IR" | "RS232"
	// projector_manufacturer?: "OPTOMA" | "EPSON"
}

export interface EidosFirmware extends BaseEidosType {
	bluetooth?: {
		paired_entries: Array<number>
		scanned_entries: Array<number>
	}
	config?: {
		fw_config: LuxcastFWPreferences
	}
	display_config?: Array<number>
	display_mode?: string
	playback_type: "EMPTY" | "SCENE" | string
	message_queue?: Array<string>
	pnc?: {
		capture_id: number | "auto_cal"
		imgs_taken: number
		img_total: number
	}
	proj_id?: number
	proj_play_starttime?: number
	status: StatusRPiDevice
	projector_power?: {
		state: ProjectorPowerState // undefined is highly unlikely, so just treat it as OFF
		controller: "OptomaRS232Controller" | "EpsonRS232Controller" | "InfraredController"
	}
	lux2lux?: Lux2LuxEidosStatus | null
	api_threads?: APIThreadStatus
}

export interface DeviceRPiRawData extends BaseDeviceRawData {
	status: StatusRPiDevice
	type_id: "dev_luxedo" | "dev_luxcast"
	product_id: number
	proj_w?: number
	proj_h?: number
	version_crnt: string
	version_tgt?: string
	version_avail: string
	version_avail_stable: string
	password?: string
	third_party_proj?: string
	eidos: EidosFirmware
	preferences: LuxcastFWPreferences
}

export interface DeviceRPi extends Device<DeviceRPiRawData> {
	_eidos: EidosFirmware
	_status: StatusRPiDevice
	typeId: "dev_luxedo" | "dev_luxcast"
	isResolutionChanging?: boolean
	firmwareVersion: string
	thirdPartyProjector?: string
	orientation?: boolean
	password?: string
}

// #endregion ================================= 	TYPES 	=================================

export class DeviceRPi extends Device<DeviceRPiRawData> {
	// #region 		=================================  Initialization 	=================================

	declare orientation?: boolean
	declare availableUpdate?: string
	declare availableUpdateBeta?: string
	protected versionTarget?: string

	constructor(data: DeviceRPiRawData) {
		super(data)
		this._rawData = data
	}

	// #endregion =================================  Initialization 	=================================
	// #region 		=================================  Import / Export  =================================

	/**
	 * Imports the resolution depending on the device's product type.
	 */
	protected importResolution(data: DeviceRPiRawData) {
		if (data.type_id === "dev_luxedo") {
			this.resX = FirmwareProductByID[data.product_id].proj_res[0]!
			this.resY = FirmwareProductByID[data.product_id].proj_res[1]!
		} else {
			this.resX = data.proj_w!
			this.resY = data.proj_h!
		}
	}

	/**
	 * Imports the device data, populating this device instance
	 * @param data The device data
	 */
	protected importData(data: DeviceRPiRawData): void {
		super.importData(data)

		this.password = data.password
		this.orientation = !!parseInt(data.orientation ?? "0")
		this.firmwareVersion = data.version_crnt
		this.thirdPartyProjector = data.third_party_proj ?? "_all_other_projectors"

		this.availableUpdateBeta = data.version_avail
		this.availableUpdate = data.version_avail_stable ?? data.version_avail
	}

	/**
	 * Converts this object to a JSON representation (used to push changes)
	 * @returns
	 */
	protected exportData(): Partial<DeviceRPiRawData> {
		return {
			name: this.name,
			ui_color: this._color,
			proj_h: this.resY,
			proj_w: this.resX,
		}
	}

	// #endregion =================================  Import / Export  =================================
	// #region 		=================================   Device Update   =================================

	// because I cannot get the user data from here,
	// we save the "includeBeta" property, assuming that was
	// passed from the user's opt_in_beta status
	allowBeta?: boolean
	isUpdateAvailable(includeBeta?: boolean): boolean {
		this.allowBeta = includeBeta
		try {
			return (
				Version.compare_strings(
					this.firmwareVersion,
					includeBeta ? this.availableUpdateBeta : this.availableUpdate
				) < 0
			)
		} catch (e) {
			console.warn("Error comparing device version to available versions.")
			return false
		}
	}

	async update(): Promise<void> {
		await LuxedoRPC.api.deviceControl.device_update(this.id!)
	}

	// #endregion =================================   Device Update   =================================
	// #region 		=================================      Getters      =================================

	/**
	 * Gets the online status of the Luxedo device
	 */
	get isOnline() {
		const status = this._status
		return status == "IDLE" || status == "PROJ_TT" || status == "PROJECT" || status == "PNC"
	}

	/**
	 * Checks if the device is busy or available for action
	 */
	get isReady() {
		return this._status == "IDLE" || this._status == "PROJ_TT" || this._status == "PROJECT"
	}

	/**
	 * Checks the eidos to verify the status of the internal projector
	 */
	get isProjectorOn() {
		if (!this.hasConnectedProjector || !this.eidos.projector_power) return false
		if (["ON", "POWERING_OFF"].includes(this.eidos.projector_power.state)) return true
		return false
	}

	/**
	 * Returns true if this device instance has an internal projector
	 */
	public get hasConnectedProjector() {
		return this instanceof DeviceRPi && this.typeId === "dev_luxcast"
	}

	/**
	 * Gets the lumen count of this device instance
	 * (NOT USED)
	 */
	get lumenCount() {
		if (
			!this.thirdPartyProjector ||
			!ThirdPartyProjectorManager.projectors[this.thirdPartyProjector]
		)
			return null
		return ThirdPartyProjectorManager.projectors[this.thirdPartyProjector].lumens ?? 3500
	}

	// #endregion =================================      Getters      =================================
	// #region 		=================================  Status Updates   =================================

	/**
	 * Called when the device's eidos is updated
	 * @param eidos
	 */
	onEidosUpdate(eidos: EidosFirmware): void
	onEidosUpdate(eidos: any): void
	onEidosUpdate(eidos: unknown): void {
		super.onEidosUpdate(eidos)

		console.warn(`[${this.id!}] EIDOS UPDATE ->`, eidos, this)

		// if (this.isUpdating && !this.isOnline)

		// DataHandlerDevice.pull([this.id!])
	}

	/**
	 * A mapping of each eidos status w/ user facing text and a color
	 */
	protected statusAppearanceMap: Record<StatusRPiDevice, DeviceStatusAppearance> = {
		OFF: { text: "Offline", color: StatusColors.off },
		LOST: { text: "Offline", color: StatusColors.off },
		NEW: { text: "Offline", color: StatusColors.off },
		UPDATING: { text: "Updating...", color: StatusColors.updating },
		IDLE: { text: "Idle", color: StatusColors.idle },
		PROJECT: { text: "Playing", color: StatusColors.playing },
		PROJ_TT: { text: "Playing", color: StatusColors.playing },
		PNC: { text: "Capturing...", color: StatusColors.projectAndCapture },
		DORMANT: { text: "Searching...", color: StatusColors.initializing },
	}

	// #endregion =================================  Status Updates   =================================
	// #region    ================================= Device Operations =================================

	// loadingPowerControl: Promise<void> | undefined = undefined

	/**
	 * Sends a projector power signal to the backend, resolves when the new projector state reflects the provided status.
	 * @param newPower The intended power signal of the internal projector
	 */
	async setProjectorPower(newPower: "ON" | "OFF"): Promise<void> {
		// if (this.loadingPowerControl) return this.loadingPowerControl
		return new Promise(async (res, rej) => {
			const resolve = (fail?: boolean) => {
				if (fail) rej()
				else res()
				// this.loadingPowerControl = undefined
			}
			if (newPower === this.eidos.projector_power?.state) return resolve()

			try {
				await LuxedoRPC.api.deviceControl.devops_set_projector_power(this.id!, newPower)
				await this.listenEidosCondition((eidos) => eidos.projector_power?.state == newPower, 120)
				resolve()
			} catch (e) {
				console.error("An error occurred while trying to set projector power.", e)
				resolve(true)
			}
		})
	}

	// #endregion ================================= Device Operations =================================

	// #region ================= Utility =================

	/**
	 * Checks if this device's fw version is at least the specified version
	 * @param fwVersion
	 */
	compareVersion(fwVersion: string) {
		const parseVersion = (version: string) => version.split(".").map(Number)

		if (!this.firmwareVersion) return undefined

		const [major1, minor1, patch1] = parseVersion(this.firmwareVersion)
		const [major2, minor2, patch2] = parseVersion(fwVersion)

		if (major1 !== major2) {
			return major1 > major2 ? 1 : -1
		}
		if (minor1 !== minor2) {
			return minor1 > minor2 ? 1 : -1
		}
		if (patch1 !== patch2) {
			return patch1 > patch2 ? 1 : -1
		}
		return 0
	}

	/**
	 * This method shows if the specified show is ready to play on this device
	 * @param show
	 * @returns
	 */
	isReadyToPlay(show: Lightshow | Scene): boolean {
		if (!this.eidos?.cache) return false

		const cache = this.eidos.cache
		return !!cache.shows[show.id!]
	}

	/**
	 * This method shows if the specified show is actively being downloaded to this device
	 * @param show
	 * @returns
	 */
	isShowDownloading(show: Lightshow | Scene) {
		if (!this.eidos?.cache) return false

		const cache = this.eidos.cache
		if (!("currently_downloading" in cache)) return false
		if (cache.currently_downloading == show.id) return true
		return cache.download_queue.includes(show.id!)
	}

	/**
	 * This method shows if the version of the specified show is newer than the version found on this device
	 * @param show
	 * @returns
	 */
	isShowOutdated(show: Lightshow | Scene, fromGroup?: boolean): boolean {
		if (show.target_device_id && show.target_device_id != this.id && !fromGroup) return false
		let showVersion = show instanceof Lightshow ? show.updated_at : show.render_ver
		if (!showVersion) return false
		console.log(show)

		const cache = this.eidos?.cache
		console.log("CHECKING CACHE", cache)
		if (!cache) return false

		const cachedShow = cache.shows[show.id!]
		console.log("CHECKING CACHED SHOW", cachedShow)
		if (!cachedShow) return true

		const cachedVersion = new Date((cachedShow.mtime + 30) * 1000)
		console.log("CACHED VERSION", cachedVersion)
		console.log("SHOW VERSION", showVersion)

		const outdated = cachedVersion < showVersion

		if (outdated || show instanceof Scene) {
			console.log(`SHOW ${show.name} IS OUTDATED? {${outdated}}`)
			return outdated
		}

		// Check if the one of the scenes from the lightshow is outdated
		for (const content of show.getScenesInOrder()) {
			if (!(content instanceof Scene)) continue
			if (this.isShowOutdated(content, fromGroup)) {
				console.log(`OUTDATED SCENE ${content.id} IN LIGHTSHOW ${show.id}`)
				return true
			}
		}

		return false
	}

	// #endregion ============== Utility =================
}
