<script lang="ts">
	import {
		DataHandlerDevice,
		type Device,
		DeviceRPi,
		DeviceGroup,
	} from "luxedo-data"
	import DeviceSelector from "../../../input/DeviceSelector.svelte"
	import { GroupEditorController } from "../GroupEditorController"
	import { asyncConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { getCurrentUser } from "../../../../../stores/UserStore"

	let group: DeviceGroup
	let disable = $state(false)

	let availableDevices: Array<DeviceRPi> = $state([])
	let selectedDevices: Array<DeviceRPi> = $state([])
	let devicesInOtherGroups: Array<DeviceRPi> = $state([])

	function updateAvailableDevices() {
		availableDevices = DataHandlerDevice.getMany().filter(
			(dev) => dev instanceof DeviceRPi
		) as Array<DeviceRPi>
		if (group)
			devicesInOtherGroups = availableDevices.filter(
				(dev) => dev.getParent() && dev.getParent() !== group
			)
		availableDevices = availableDevices.filter(
			(dev) => !(dev.getParent() && dev.getParent() !== group)
		)
	}

	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
		selectedDevices = ctx.devices
		updateAvailableDevices()
	})

	DataHandlerDevice.addListener(updateAvailableDevices)

	function onDeviceSelect(device: DeviceRPi) {
		let isPro = getCurrentUser().hasPriv("pro")

		if (selectedDevices.includes(device)) {
			// if removing
			selectedDevices.splice(selectedDevices.indexOf(device), 1)
		} else {
			// if adding
			if (!isPro && selectedDevices.length == 2)
				Toast.action(
					"Upgrade to a professional account to create larger device groups.",
					"Upgrade",
					() => alert("NEED UPGRADE FLOW")
				)

			selectedDevices.push(device)
		}
		selectedDevices = [...selectedDevices]
	}

	async function onSelectDeviceFromOtherGroup(device: DeviceRPi) {
		const parent = device.getParent()!
		try {
			await asyncConfirmOverlay({
				title: "Confirm",
				prompt: [
					`Adding this device will deactivate the following group:`,
					`<b>${parent.name}</b>`,
					`Continue?`,
				],
				confirmText: "Yes",
				denyText: "No",
			})
		} catch (e) {
			selectedDevices = [...selectedDevices]
			return
		}

		await parent.deactivate()
		Toast.success(`Group ${parent.name} deactivated successfully!`)
		updateAvailableDevices()
		onDeviceSelect(device)
	}

	function getDeviceSelected(devices: Array<Device>, device: Device) {
		return devices.includes(device)
	}

	function next() {
		GroupEditorController.setSelectedDevices(selectedDevices)
		GroupEditorController.next()
	}

	function checkIfAtLimit(devices: Array<DeviceRPi>) {
		const isPro = getCurrentUser().hasPriv("pro")
		if (isPro) return (disable = false)
		if (devices.length >= 2) return (disable = true)
		else disable = false
	}

	// $effect(() => {
	// 	checkIfAtLimit(selectedDevices)
	// })
</script>

<h3>Select which devices will be included in this group</h3>
<div class="scrollable">
	{#each availableDevices as device}
		<DeviceSelector
			type="checkbox"
			disabled={disable && !getDeviceSelected(selectedDevices, device)}
			{device}
			isSelected={getDeviceSelected(selectedDevices, device)}
			onSelect={onDeviceSelect}
		/>
	{/each}
	{#if devicesInOtherGroups.length > 0}
		<h5 class="divider">Devices in other groups</h5>
		{#each devicesInOtherGroups as device}
			<DeviceSelector
				type="checkbox"
				{device}
				disabled={disable && !getDeviceSelected(selectedDevices, device)}
				isSelected={getDeviceSelected(selectedDevices, device)}
				onSelect={onSelectDeviceFromOtherGroup}
			/>
		{/each}
	{/if}
	{#if disable}
		<div class="upgrade-container">
			<p class="upgrade-prompt">
				Upgrade to a professional account to create larger device groups.
			</p>
			<!-- <button
				class="outline-button"
				onclick={() => alert("NOT YET IMPLEMENTED")}>Upgrade Now</button
			> -->
		</div>
	{/if}
</div>

<div id="step-buttons">
	<button
		id="next-step-button"
		class="outline-button"
		onclick={next}
		disabled={selectedDevices.length === 0}
	>
		Next
	</button>
</div>

<style>
	.scrollable {
		max-height: 70vh;
		overflow-y: scroll;
	}

	h5.divider {
		margin: 1.5rem 0 0;
		font-size: var(--font-size);
	}

	.upgrade-prompt {
		color: var(--color-main);
		margin-bottom: 0.5rem;
	}

	.upgrade-container {
		margin-top: 1rem;
	}
</style>
