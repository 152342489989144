import {
	API_THREAD_DEVICE_ROUTES,
	ApiThreadDeviceRoute as APIThreadDeviceRoute,
	Device,
	DeviceRPi,
} from "../../entries/Device"
import { FeatureNotAvailableError } from "../../types/ErrorTypes"

const MAX_UPDATES_WITHOUT_THREAD = 3

export namespace APIThread {
	class APIThreadError extends Error {
		device: Device
		route: APIThreadDeviceRoute

		constructor(device: Device, route: APIThreadDeviceRoute, error: string) {
			super(error)

			this.device = device
			this.route = route
		}
	}

	export class APIThreadNotAvailableError extends APIThreadError {} // if the route does not exist
	export class APIThreadChangedError extends APIThreadError {} // if the id changed without resolving
	export class APIThreadFailedError extends APIThreadError {
		constructor(device: Device, route: APIThreadDeviceRoute, error: string) {
			super(device, route, error)
			this.message = this.parseErrorString(route, error)
		}

		private parseErrorString(route: APIThreadDeviceRoute, errorString: string) {
			if (route === "master_play_scene") {
				switch (errorString) {
					case "ERR_UNKNOWN":
						return "An unknown error occurred"
					case "ERR_TIMEOUT":
						return "Failed to start playback in sync, please try again"
					case "ERR_CLIENT_UNREACHABLE":
						return "Failed to establish communication between all devices"
					case "ERR_ACCESS":
						return "The show is invalid and must be re-rendered"
					case "ERR_CACHE":
						return "One or more devices do not have this show downloaded"
					default:
						return errorString
				}
			}

			return errorString
		}
	} // if the thread reported an error

	/**
	 * For any device with the "API_THREADS" feature, listen for the specified process to resolve.
	 * @param device the device to listen to (e.g., 1517)
	 * @param route the route to listen to (e.g., play_scene, projector_power_on, ect)
	 * @returns a promise which resolves with the "result" from a successful thread
	 * @throws
	 *  FeatureNotAvailableError if the specified device does not have api_threads available in eidos
	 *  APIThreadNotAvailableError
	 *    - if the thread route is not valid (does not exist in Device.ts:API_THREAD_DEVICE_ROUTES)
	 *    - if the thread route is not available from the device eidos block
	 *  APIThreadChangedError
	 *    - if the thread's id changed without completing
	 *  APIThreadFailedError
	 *    - if the thread provided error data with the status of ERROR
	 */
	export async function waitFor(device: Device, route: APIThreadDeviceRoute, ignoredThreadID?: number): Promise<any> {
		// this is a bit redundant for TypeScript reasons
		if (!device.features.includes("API_THREADS") || !(device instanceof DeviceRPi))
			throw new FeatureNotAvailableError(device, "API_THREADS")

		// if the specified thread is not defined in  api thread device routes
		if (!API_THREAD_DEVICE_ROUTES.includes(route))
			throw new APIThreadNotAvailableError(device, route, `${route} is not a valid api thread endpoint to listen to.`)

		return new Promise((res, rej) => {
			// id of the listener
			let updateListenerId: string
			// id of the thread (according to the device)
			let threadId: number

			const resolve = (data: any) => {
				console.warn("RESOLVE CALLED")

				device.removeUpdateListener(updateListenerId)
				res(data)
			}

			const reject = (error: Error) => {
				console.warn("REJECT CALLED")
				device.removeUpdateListener(updateListenerId)
				rej(error)
			}

			updateListenerId = device.addUpdateListener((dev) => {
				let updatesWithoutThread = 0
				const thread = dev.eidos?.api_threads[route]
				console.log("xxx EIDOS UPDATE", dev.eidos, thread)

				if (!thread || thread.id === ignoredThreadID) {
					// if the thread does not exist, wait for a few more updates before throwing an error
					updatesWithoutThread++
					if (updatesWithoutThread > MAX_UPDATES_WITHOUT_THREAD)
						return reject(
							new APIThreadNotAvailableError(device, route, `Cannot get data for ${route} from device ${dev.id}.`)
						)
				} else {
					// otherwise, the thread exists
					if (threadId && thread.id != threadId)
						return reject(new APIThreadChangedError(device, route, `Lost track of ${route} from device ${dev.id}.`))

					threadId = thread.id
					if (thread.status === "SUCCESS") {
						resolve(thread.result)
					}

					if (thread.status === "ERROR") {
						reject(new APIThreadFailedError(device, route, thread.error))
					}
				}
			})
		})
	}
}
