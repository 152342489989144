<script lang="ts">
	import {
		DataHandlerDevice,
		DeviceGroup,
		DeviceRPi,
		TEMPORARY_PLAY_NOW_EVENT_NAME,
		TimetableEvent,
		type Device,
		type Lightshow,
		type Scene,
	} from "luxedo-data"
	import { PlayIcon, StopIcon } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import { LoadingSpinner } from "svelte-comps/loading"
	import { DevicePlaybackManager } from "luxedo-data"
	import { openPopupOverlay } from "svelte-comps/overlay"
	import { ScheduleController } from "../../../../routes/schedule/ScheduleController"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { get } from "svelte/store"
	import { APIThread } from "luxedo-data/src/modules/device-operation-managers/DeviceAPIThread"

	interface Props {
		show: Scene | Lightshow
		device: Device
	}

	let { show, device }: Props = $props()

	let isPlaying = $state(false)
	let isWaiting = $state(false)
	let isOffline = false
	let isDisabled = $state(false)

	let activeEvent: TimetableEvent

	DevicePlaybackManager.subscribe((ctx) => {
		const devStatus = ctx[device.id]

		if (!devStatus) {
			isOffline = false
			isWaiting = false
			isPlaying = false
			activeEvent = undefined
			return
		}

		const isDevicePlaying = devStatus.isPlaying
		const showScene = devStatus.scene
		const showLightshow = devStatus.lightshow

		// if the device is playing and the show matches this show,
		// OR the device is a device group (this ensures the stop button renders if any child device is in a playback status)
		if (
			isDevicePlaying &&
			(show.id === showScene?.id ||
				show.id === showLightshow?.id ||
				device instanceof DeviceGroup)
		) {
			isPlaying = true
			activeEvent = devStatus.event
		} else {
			isPlaying = false
			activeEvent = undefined
		}
	})

	let listeners: Array<string> = []

	listeners.push(
		device.addUpdateListener((dev) => {
			isOffline = !dev.isOnline
		})
	)

	async function stopPlayback() {
		isWaiting = true

		if (!activeEvent)
			activeEvent = await DevicePlaybackManager.getActiveEvent(device)

		const clearEvent = async () => {
			return new Promise<void>(async (res, rej) => {
				let listener: string
				let eidosDevice = device

				const resolve = () => {
					res()
					Toast.success("Playback stopped.")
					isPlaying = false
					isWaiting = false
					eidosDevice.removeUpdateListener(listener)
				}

				if (device instanceof DeviceGroup)
					eidosDevice = device.getChildDevices().find((dev) => dev.isOnline)

				listener = eidosDevice.addUpdateListener(async () => {
					if (DevicePlaybackManager.checkIsPlaying(device) === false)
						return resolve()
					if (
						(await DevicePlaybackManager.getActiveEvent(device)) !== activeEvent
					)
						return resolve()
				})

				await device.timetableManager.deleteEvent(activeEvent)
				await ScheduleController.Calendar.refreshEvents({
					deviceFilter: get(SelectedDeviceStore),
				})
			})
		}

		try {
			if (activeEvent?.name === TEMPORARY_PLAY_NOW_EVENT_NAME) clearEvent()
			else if (
				(device instanceof DeviceRPi &&
					device.eidos.display_mode === "PREVIEW") ||
				(device instanceof DeviceGroup &&
					DevicePlaybackManager.checkIsPlayingForGroup(device))
			) {
				await DevicePlaybackManager.cancelPreview(device)
				// await disableButtonForDeviceGroup()
				Toast.success("Playback stopped.")
				isPlaying = false
				isWaiting = false
			} else {
				openPopupOverlay({
					prompt: [
						"This show has been scheduled to play.",
						"To cancel it, please delete the event from the scheduler.",
					],
					onVerify: () => {
						isWaiting = false
					},
				})
			}
		} catch (e) {
			console.error("Error clearing playback... ", e)
			Toast.error("Error canceling playback.")
		}
	}

	async function playOnDevice() {
		try {
			isWaiting = true

			const isGroup = device instanceof DeviceGroup
			await DevicePlaybackManager.playOnDevice(device, show, {
				onFirstDownload: () => Toast.text("Waiting for show to download..."),
				onPlayingOutdated: () =>
					Toast.text(
						"Playing old version of this show. The playback will restart as soon as the new version is downloaded."
					),
				onPoweringProjector: () =>
					Toast.text(`Powering projector${isGroup ? "s" : ""} on...`),
			})

			// await disableButtonForDeviceGroup()
			Toast.success("Playback started")

			isPlaying = true
		} catch (e: any) {
			console.error("Error playing show on device... ", e)
			if (e instanceof APIThread.APIThreadFailedError) Toast.error(e.message)
			else if (e && e.statusCode === 701)
				Toast.error(
					"Device appears to be disconnected, ensure is connected to Wi-Fi and try again."
				)
			else
				Toast.error(
					"Unable to play show at this time. Please refresh and try again."
				)
		} finally {
			isWaiting = false
		}
	}

	let timeout: number
	/**
	 * Adds delay between playback update and the rendering of the playback update
	 */
	async function disableButtonForDeviceGroup() {
		return new Promise<void>((res) => {
			if (!(device instanceof DeviceGroup)) return res()

			if (timeout) clearTimeout(timeout)
			timeout = undefined

			isDisabled = true
			setTimeout(() => {
				isDisabled = false
				res()
			}, 5000)
		})
	}

	async function handleClick() {
		if (isOffline) {
			Toast.error(
				"Device appears to be disconnected, ensure is connected to Wi-Fi and try again."
			)
			return
		}

		if (isWaiting) return

		if (isPlaying) {
			stopPlayback()
		} else {
			playOnDevice()
		}
	}

	function registerListener(dev: Device) {
		dev.addUpdateListener((dev) => {
			isOffline = !dev.isOnline
		})
	}

	registerListener(device)
</script>

{#if isWaiting || isDisabled}
	<LoadingSpinner color="var(--color-main)" height="3.5rem" />
{:else}
	<button
		id="play-lightshow-btn"
		class="icon"
		title={isPlaying ? "Stop Playback" : "Play on Projector"}
		onclick={handleClick}
	>
		{#if isPlaying}
			<StopIcon />
		{:else}
			<PlayIcon />
		{/if}
	</button>
{/if}

<style>
	#play-lightshow-btn {
		outline: 1px solid var(--color-main);
		padding: 1rem;
		transition:
			outline-offset 250ms ease-out,
			outline-color 250ms;
	}

	#play-lightshow-btn:hover,
	#play-lightshow-btn:focus-visible {
		outline-offset: 0.5rem;
	}

	#play-lightshow-btn :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
