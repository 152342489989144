<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { GroupOverlapController } from "../GroupOverlapController"
	import InputsSlotOverlapInstance from "./Inputs_SlotOverlapInstance.svelte"
	import { GroupCanvasController } from "../../../canvas/GroupCanvasController"

	let overlapInstances: Array<GroupOverlapController.OverlapInstance> = $state(
		[]
	)
	GroupOverlapController.subscribe(
		(ctx) => (overlapInstances = ctx.overlapInstances)
	)
	$inspect(overlapInstances)

	let showSnapshots: boolean = $state()

	GroupCanvasController.subscribe((ctx) => (showSnapshots = ctx.showSnapshots))

	function onSnapshotVisibilityUpdate(newVal: boolean) {
		GroupCanvasController.setSnapshotVisibility(newVal)
	}
</script>

<div class="overlap-instances-container">
	<div class="flex-row">
		<h3>Overlapping Sections</h3>
		<div class="switch-container">
			<span class="label"> Show Snapshots? </span>
			<ToggleSwitch
				isActive={showSnapshots}
				onUpdate={onSnapshotVisibilityUpdate}
			/>
		</div>
	</div>
	<p class="info">
		Click an overlapped section below to adjust its blend settings.
	</p>
	<ul class="instance-selector">
		{#each overlapInstances as instance (instance.id)}
			<InputsSlotOverlapInstance overlapInstance={instance} />
		{/each}
	</ul>
</div>

<style>
	h3 {
		margin: 0;
		font-size: var(--h1);
	}

	.info {
		font-size: var(--h3);
		line-height: 1.5em;
	}

	.instance-selector {
		padding-right: 1rem;
	}

	.switch-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 0 0 1rem;
	}

	.switch-container .label {
		font-size: var(--h3);
		margin-right: 0.5rem;
		color: var(--color-text);
	}
</style>
