<script lang="ts">
	import { run } from "svelte/legacy"

	import { DataHandlerDevice, Device, DeviceGroup } from "luxedo-data"
	import DeviceFilterInstance from "../../../reusable/device-filter/DeviceFilter_Instance.svelte"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"

	interface Props {
		deviceView?: "Devices" | "Groups"
	}

	let { deviceView = "Devices" }: Props = $props()
	let activeDevices = $state([])
	let inactiveDevices = $state([])

	let selectedDevice: Device = $state()

	SelectedDeviceStore.subscribe((dev) => {
		selectedDevice = dev
	})

	DataHandlerDevice.addListener(() => {
		updateRelevantDevices()
	})

	function onSetDeviceFilter(dev: Device) {
		if (selectedDevice && dev.id == selectedDevice.id) return
		SelectedDeviceStore.set(dev)
	}

	function updateRelevantDevices() {
		let allDevices = DataHandlerDevice.getMany()

		if (deviceView === "Devices") {
			activeDevices = allDevices.filter((dev) => !(dev instanceof DeviceGroup))
			inactiveDevices = []
		} else {
			activeDevices = allDevices.filter(
				(dev) => dev instanceof DeviceGroup && !dev.deactivated
			)
			inactiveDevices = allDevices.filter(
				(dev) => dev instanceof DeviceGroup && dev.deactivated
			)
		}
	}

	run(() => {
		deviceView && updateRelevantDevices()
	})
</script>

{#each activeDevices as device}
	<DeviceFilterInstance
		{device}
		filterDevice={selectedDevice}
		{onSetDeviceFilter}
		hideSettings={true}
	/>
{/each}
{#if inactiveDevices.length > 0}
	<span class="divider">
		<div class="divider-line"></div>
		<h5>Inactive Groups</h5>
		<div class="divider-line"></div>
	</span>
	{#each inactiveDevices as device}
		<DeviceFilterInstance
			{device}
			filterDevice={selectedDevice}
			{onSetDeviceFilter}
			hideSettings={true}
		/>
	{/each}
{/if}

<style>
	.divider {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 1rem 0;
	}

	.divider h5 {
		margin: 0;
		color: var(--color-text-light);
		text-align: center;
		/* Should always be forced to stay on one line */
		white-space: nowrap;
	}

	.divider-line {
		display: flex;
		box-sizing: border-box;
		margin: 0 1rem;
		width: 100%;
		height: 1px;
		flex-shrink: 1;
		background-color: var(--color-accent);
	}
</style>
